import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Button,
  Slide,
  useScrollTrigger,
  Box,
} from "@material-ui/core"

import theme from "../theme"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
  },
  menu: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(5),
    textDecoration: "none",
  },
  activeMenu: {
    color: theme.palette.primary,
  },
}))

const HideOnScroll = props => {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const HeaderComponent = props => {
  const classes = useStyles(theme)

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar style={{ background: theme.palette.background.default }}>
          <Toolbar>
            <Link to="/">
              <img
                src={require("../images/uxofeverything.png")}
                alt="logo"
                width="207"
              />
            </Link>
            <div className={classes.logo} />
            <Link
              to="/"
              className={classes.menu}
              activeStyle={{ color: "#045CB6" }}
            >
              Home
            </Link>
            <Link
              to="/articles"
              className={classes.menu}
              activeStyle={{ color: "#045CB6" }}
            >
              Articles
            </Link>
            <Link
              to="/companies"
              className={classes.menu}
              activeStyle={{ color: "#045CB6" }}
            >
              Companies
            </Link>
            <Box ml={5}>
              <Button variant="outlined" onClick={() => navigate("/contact")}>
                Contact Us
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-173905209-1"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-173905209-1');
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WBKCND6');
          window.smartlook||(function(d) {
          var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
          var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
          c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
          })(document);
          smartlook('init', '27b8873e1974f391d265b7b5878490f954dbe368');
          `,
        }}
      />
    </div>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

export default HeaderComponent
